import { navigate } from 'astro:transitions/client'
import { Button } from '@/components/ui/atoms/button'
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuList,
  NavigationMenuSubItem,
  NavigationMenuTrigger,
} from '@/components/ui/atoms/navigation-menu'
import { STUDENT_AREA_URL, SUBSCRIPTION } from '@/consts'
import { cn } from '@/lib/utils'
import {
  actions as authentication_action,
  store as authentication_store,
} from '@/store/authentication'
import { store as checkout_store } from '@/store/checkout'
import type { IAccount, IAuthentication } from '@/types/account'
import { useStore } from '@nanostores/react'
import {
  ChevronDown,
  ChevronUp,
  LogOut,
  Menu,
  RotateCw,
  Search,
  ShoppingBasket,
  SquareArrowOutUpRight,
  X,
} from 'lucide-react'
import { atom } from 'nanostores'
import type React from 'react'
import { useEffect, useState } from 'react'
import UserDropdownMenu from './user-dropdown-menu'

export const menuAtom = atom<boolean>(false)
export const moreMenuAtom = atom<boolean>(false)

export default function Header() {
  const navigation: {
    title: string
    href: string
    children?: Array<{ title: string; href: string }>
  }[] = [
    {
      title: 'CNU',
      href: 'https://assinaturas.direcaoconcursos.com.br/cnu',
    },
    {
      title: 'Caixa',
      href: 'https://assinaturas.direcaoconcursos.com.br/caixa-economica',
    },
    {
      title: 'Cursos',
      href: '/cursos',
    },
    {
      title: 'Conteúdo Gratuito',
      href: '/conteudo-gratuito',
    },
    {
      title: 'Notícias',
      href: '/noticias',
    },
    {
      title: 'Mais',
      href: '#',
      children: [
        {
          title: 'Concursos',
          href: '/concursos',
        },
        {
          title: 'Módulos',
          href: '/modulos',
        },
        {
          title: 'Sobre',
          href: '/sobre',
        },
        {
          title: 'Artigos',
          href: '/artigos',
        },
        {
          title: 'Professores',
          href: '/professores',
        },
      ],
    },
  ]

  const $open = useStore(menuAtom)
  const $openMore = useStore(moreMenuAtom)
  const $cart = useStore(checkout_store.cart)
  const $authentication = useStore(authentication_store.authentication)

  const [cartItems, setCartItems] = useState(0)
  const [user, setUser] = useState<(IAccount & IAuthentication) | null>(null)

  useEffect(() => {
    setCartItems($cart?.length || 0)
  }, [$cart])

  useEffect(() => {
    setUser($authentication)
  }, [$authentication])

  const handleNavigation = (ev: React.MouseEvent<HTMLAnchorElement>) => {
    ev.preventDefault()
    if (open) menuAtom.set(!open)
    navigate(ev.currentTarget.href)
  }

  const handleLogout = async (ev: React.MouseEvent<HTMLElement>) => {
    ev.preventDefault()
    if (open) menuAtom.set(!open)
    await fetch('/api/proxy/signout')
    authentication_action.signout()
  }

  const handleStudentArea = (ev: React.MouseEvent<HTMLElement>) => {
    ev.preventDefault()
    if (open) menuAtom.set(!open)
    if (user?.token) window.open(`${STUDENT_AREA_URL}?auth=${user?.token}`)
  }

  return (
    <header>
      <nav className="bg-zinc-900 text-zinc-50 fixed w-full z-30 top-0 left-0 text-sm backdrop-blur supports-[backdrop-filter]:bg-zinc-900/80 border-b border-border/10">
        <div
          className="w-full h-1 bg-gradient-to-r from-zinc-500/20 to-zinc-500/60 absolute"
          style={{ display: 'none' }}
        >
          <div
            id="loading-bar"
            className="h-full bg-gradient-to-r from-amber-500 to-orange-500 rounded-r-xl transition-all ease-in-out duration-300"
            style={{ width: 0 }}
          />
        </div>
        <RotateCw
          id="loading-spinner"
          className="loading h-5 w-5 stroke-orange-400 animate-spin absolute -top-6 xl:top-1/3 right-5"
          style={{ display: 'none' }}
        />
        <div className="mx-auto max-w-7xl p-2 sm:px-4 lg:px-6">
          <div className="relative flex h-16 items-center justify-between">
            <div className="absolute inset-y-0 left-0 flex items-center lg:hidden">
              <button
                onClick={() => menuAtom.set(!$open)}
                type="button"
                className="relative inline-flex items-center justify-center p-2 text-white focus:outline-none"
              >
                <span className="absolute -inset-0.5" />
                <span className="sr-only">Open main menu</span>
                {$open ? (
                  <X className="block h-6 w-6" aria-hidden="true" />
                ) : (
                  <Menu className="block h-6 w-6" aria-hidden="true" />
                )}
              </button>
            </div>
            <div className="flex flex-1 items-center ml-12 sm:justify-center sm:ml-0 lg:items-stretch lg:justify-start">
              <div className="flex flex-shrink-0 items-center w-1/2 sm:w-auto">
                <a href="/">
                  <img
                    loading="eager"
                    src="/images/logo-white.webp"
                    height={43}
                    width={170}
                    alt="Logo do Direção Concursos"
                  />
                </a>
              </div>
              <div className="hidden lg:ml-2 lg:block">
                <div className="flex space-x-4">
                  <NavigationMenu>
                    <NavigationMenuList>
                      {navigation.map(({ title, href, children }) => (
                        <NavigationMenuItem key={title}>
                          {children?.length ? (
                            <>
                              <NavigationMenuTrigger className="text-zinc-300 hover:bg-zinc-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium bg-transparent">
                                {title}
                              </NavigationMenuTrigger>
                              <NavigationMenuContent>
                                <ul className="p-4 w-full md:w-[180px] lg:w-[200px]">
                                  {children.map(({ title, href }) => (
                                    <NavigationMenuSubItem
                                      key={title}
                                      href={href}
                                      title={title}
                                    />
                                  ))}
                                </ul>
                              </NavigationMenuContent>
                            </>
                          ) : (
                            <a
                              data-astro-prefetch
                              className="group h-10 w-max items-center justify-center transition-colors focus:outline-none disabled:pointer-events-none disabled:opacity-50 text-zinc-300 hover:bg-zinc-700 hover:text-white flex rounded-md p-3 text-sm font-medium"
                              href={href}
                            >
                              {title}
                            </a>
                          )}
                        </NavigationMenuItem>
                      ))}
                    </NavigationMenuList>
                  </NavigationMenu>
                </div>
              </div>
            </div>
            <div className="absolute inset-y-0 right-0 flex items-center">
              <div className="flex items-center space-x-4 px-2">
                <Button
                  variant={'outline'}
                  className={cn(
                    'w-[160px] xl:w-[250px] hidden xl:flex pl-6 text-left text-xs font-normal bg-transparent text-zinc-300 rounded-full border-zinc-400 hover:bg-transparent hover:text-zinc-400',
                  )}
                >
                  <span>Pesquisar...</span>
                  <Search className="ml-auto h-4 w-4" />
                </Button>
                <a
                  className="hidden md:block xl:hidden text-white"
                  href="/search"
                >
                  <Search />
                </a>

                <div className="hidden lg:block">
                  <UserDropdownMenu
                    user={user}
                    handleStudentArea={handleStudentArea}
                    handleLogout={handleLogout}
                  />
                </div>

                <Button
                  className="rounded-full cursor-pointer"
                  onClick={() => {}}
                  size="icon"
                  asChild
                  variant="ghost"
                >
                  <a href="/carrinho" aria-label="Carrinho">
                    <div>
                      {cartItems > 0 && (
                        <span className="absolute flex h-5 w-5 top-3 ml-3">
                          <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-blue-400 opacity-75" />
                          <span className="relative inline-flex text-xs items-center justify-center rounded-full h-5 w-5 bg-sky-500 text-white">
                            {cartItems > 9 ? '9+' : cartItems}
                          </span>
                        </span>
                      )}
                      <ShoppingBasket />
                    </div>
                  </a>
                </Button>
                <Button
                  variant="default"
                  asChild
                  className="hidden px-6 lg:block"
                >
                  <a
                    href={SUBSCRIPTION}
                    target="_blank"
                    aria-label="Assinaturas"
                    rel="noreferrer"
                  >
                    Assine já
                  </a>
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div
          className={cn(
            'lg:hidden transition-all duration-300 h-screen',
            !$open && 'hidden',
          )}
        >
          <div className="space-y-1 px-2 pb-3 pt-2">
            <ul className="font-medium flex flex-col md:p-0 lg:flex-row lg:space-x-2 md:mt-0">
              {navigation.map(({ title, href, children }) => (
                <li key={title}>
                  {children?.length ? (
                    <>
                      <button
                        type="button"
                        onClick={() => moreMenuAtom.set(!$openMore)}
                        className="flex w-full justify-between items-center rounded-md px-3 py-2 text-base font-medium transition-colors focus:outline-none text-zinc-300 hover:bg-zinc-700 hover:text-white"
                      >
                        {title}
                        {$openMore ? (
                          <ChevronUp
                            className="relative top-[1px] ml-1 h-3 w-3 transition duration-200 group-data-[state=open]:rotate-180"
                            aria-hidden="true"
                          />
                        ) : (
                          <ChevronDown
                            className="relative top-[1px] ml-1 h-3 w-3 transition duration-200 group-data-[state=open]:rotate-180"
                            aria-hidden="true"
                          />
                        )}
                      </button>
                      <div
                        className={cn(
                          'z-10 font-normal divide-y shadow w-full ml-4',
                          !$openMore && 'hidden',
                        )}
                      >
                        <ul
                          className="py-2 text-sm text-gray-700 dark:text-gray-400"
                          aria-labelledby="dropdownLargeButton"
                        >
                          {children.map(({ title, href }) => (
                            <a
                              key={title}
                              className="text-zinc-300 hover:bg-zinc-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium"
                              onClick={handleNavigation}
                              href={href}
                            >
                              {title}
                            </a>
                          ))}
                        </ul>
                      </div>
                    </>
                  ) : (
                    <a
                      className="text-zinc-300 hover:bg-zinc-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium"
                      onClick={handleNavigation}
                      href={href}
                    >
                      {title}
                    </a>
                  )}
                </li>
              ))}
              {user?.token && (
                <>
                  <li>
                    <div
                      className="text-zinc-300 hover:bg-zinc-700 hover:text-white flex justify-between items-center rounded-md px-3 py-2 text-base font-medium"
                      onClick={handleStudentArea}
                      onKeyDown={() => {}}
                    >
                      <span>Área do Aluno</span>
                      <SquareArrowOutUpRight
                        className="relative top-[1px] ml-1 h-3 w-3 transition duration-200 group-data-[state=open]:rotate-180"
                        aria-hidden="true"
                      />
                    </div>
                  </li>
                  <li className="flex mt-20 px-3 py-2">
                    <div
                      className="flex items-center justify-center border-primary border-2 text-center w-full text-primary hover:bg-zinc-700 hover:text-white rounded-md px-3 py-2 text-base font-medium"
                      onClick={handleLogout}
                      onKeyDown={() => {}}
                    >
                      <LogOut className="mr-2 h-4 w-4" />
                      <span>Sair</span>
                    </div>
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </header>
  )
}
